// Here you can add other styles

#sq-ccbox {
  float: left;
  vertical-align: top;
  font-weight: bold;
}

.sq-input {
  display: inline-block;
  border: 1px solid #E0E2E3;
  border-radius: 4px;
  height: calc(1.5em + 0.75rem - 2px);
  padding: 0.375rem 0.75rem;
}

.sq-input--focus {
  border-color: #99258b;
  box-shadow: 0 1px 1px #99258b inset, 0 0 8px #99258b;
}

.sq-input--error {
  border: 1px solid #bd0303;
}

.pointer {
  cursor: pointer;
}

// react-step-progress-bar styles
.indexedStepContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -10px;
  width: 75px;
}

.indexedStep {
  color: #6e6e6e;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #53286e;
  color: white;
}

.indexedStep.accomplished.invalid {
  background-color: #bd0303;
  color: white;
}

.indexedStep.invalid {
  background-color: #FC9191;
  color: white;
}

.indexedStepText {
  color: #6e6e6e;
  padding-top: 5px;
  text-align: center;
}

.noBorder {
  border-bottom: 0 !important;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.rbc-event-label {
  display: none !important;
}

.hide-time-indicator .rbc-current-time-indicator {
  display: none;
}
